import { type Accessor, createEffect } from 'solid-js';

export const useLockBodyScroll = (locked: Accessor<boolean>) => {
  createEffect(() => {
    if (locked()) {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.documentElement.style.paddingRight = `${scrollbarWidth}px`;
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.paddingRight = '';
      document.documentElement.style.overflow = '';
    }
  });
};
