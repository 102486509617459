import { type Component, createSignal, Show, createMemo, createEffect } from 'solid-js';
import { IconChevronLeft, IconChevronRight } from '~/components/ui/Icons';
import { useLockBodyScroll } from '~/hooks/useLockBodyScroll';
import { IconLoader, IconX } from './icons';

const [imageUrl, setImageUrl] = createSignal<string | undefined>();
const [images, setImages] = createSignal<string[]>([]);
const [currentIndex, setCurrentIndex] = createSignal<number>(0);
const [isOpen, setIsOpen] = createSignal<boolean>(false);
const [isLoading, setIsLoading] = createSignal<boolean>(true);

export const preview = (url: string) => {
  setImageUrl(url);
  setImages([url]);
  setCurrentIndex(0);
  setIsOpen(true);
};

export const previewMultiple = (imgs: string[], index?: number) => {
  setImages(imgs);
  setCurrentIndex(index || 0);
  setImageUrl(undefined);
  setIsOpen(true);
};

export const ImagePreview: Component = () => {
  useLockBodyScroll(() => isOpen());

  const currentImage = createMemo(() => images()[currentIndex()] || imageUrl());

  const close = () => {
    setIsOpen(false);
    setImageUrl(undefined);
    setImages([]);
    setCurrentIndex(0);
  };

  const changeIndex = (direction: 'prev' | 'next') => {
    setCurrentIndex((prevIndex) => {
      const newIndex = direction === 'prev' ? prevIndex - 1 : prevIndex + 1;
      return Math.max(0, Math.min(newIndex, images().length - 1));
    });
    setIsLoading(true);
  };

  createEffect(() => {
    if (isOpen()) {
      document.body.style.overflow = 'hidden';
      setIsLoading(true);
    } else {
      document.body.style.overflow = '';
    }
  });

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <Show when={isOpen() && currentImage()}>
      <div class="fixed inset-0 z-50 flex items-center justify-center">
        <div class="absolute inset-0 -z-10 bg-black/80" onClick={close} />
        <button class="absolute right-4 top-3 z-30 rounded-full border-2 border-white p-2 text-white hover:bg-white/20" onClick={close}>
          <IconX aria-label="Close" />
        </button>

        <Show when={isLoading()}>
          <div class="absolute inset-0 flex items-center justify-center">
            <IconLoader class="size-10 animate-spin text-white" />
          </div>
        </Show>

        {/* Left navigation area */}
        <Show when={currentIndex() > 0}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              changeIndex('prev');
            }}
            class="group absolute inset-y-0 left-0 z-20 flex w-1/3 cursor-pointer select-none items-center justify-start bg-transparent hover:bg-[#2E2E2E]/50">
            <IconChevronLeft class="ml-4 size-10 rounded-full bg-[#2E2E2E]/50 p-2 text-white transition-colors group-hover:bg-primary-color" />
          </div>
        </Show>

        <img
          class="max-h-full max-w-[1000px] select-none bg-faded"
          src={currentImage()}
          onLoad={handleImageLoad}
          style={{ display: isLoading() ? 'none' : 'block' }}
        />

        {/* Right navigation area */}
        <Show when={currentIndex() < images().length - 1}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              changeIndex('next');
            }}
            class="group absolute inset-y-0 right-0 z-20 flex w-1/3 cursor-pointer select-none items-center justify-end bg-transparent hover:bg-[#2E2E2E]/50">
            <IconChevronRight class="mr-4 size-10 rounded-full bg-[#2E2E2E]/50 p-2 text-white transition-colors group-hover:bg-primary-color" />
          </div>
        </Show>
      </div>
    </Show>
  );
};
